@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.sweet {
  background-color: black;
}

input,
textarea {
  @apply outline-none border-2 border-blue-400 p-2 bg-emerald-700 placeholder:text-white;
}

.middle {
  @apply flex flex-col items-center justify-center;
}

.container {
  width: 100px;
  height: 100px;
  display: grid;
  place-items: center;
  perspective: 1000px;
  perspective-origin: 76% 27%;
}

.circle {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 0.5rem dashed #0ff;
  transform-style: preserve-3d;
  border-radius: 50%;
}

.circle-1 {
  transform: rotateY(70deg) rotateX(40deg);
  animation: spin 6s infinite linear;
}

.circle-2 {
  transform: rotateY(70deg) rotateX(-40deg);
  animation: one 6s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotateY(70deg) rotateX(40deg);
  }
  100% {
    transform: rotateY(70deg) rotateX(40deg) rotateZ(360deg);
  }
}
@keyframes one {
  0% {
    transform: rotateY(70deg) rotateX(-40deg);
  }
  100% {
    transform: rotateY(70deg) rotateX(-40deg) rotateZ(360deg);
  }
}

.container-2 {
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  perspective: 1000px;
  perspective-origin: 76% 27%;
}

.c {
  position: absolute;
  width: 80px;
  height: 80px;
  border: 2px dashed #0ff;
  transform-style: preserve-3d;
  border-radius: 50%;
}

.c-1 {
  transform: rotateY(70deg) rotateX(40deg);
  animation: spin 6s infinite linear;
}

.c-2 {
  transform: rotateY(70deg) rotateX(-40deg);
  animation: one 6s infinite linear;
}

.n {
  font-size: 1.5rem;
}

.move {
  animation: turn 20s linear infinite;
}

.spinName {
  animation: spinbox 2s linear infinite;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

@keyframes spinbox {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1.5rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes turn {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
